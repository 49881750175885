<!--
 * @Author: Libra
 * @Date: 2021-05-21 16:23:26
 * @LastEditTime: 2021-09-10 11:37:59
 * @LastEditors: Libra
 * @Description: 浏览器不兼容反馈页面
 * @FilePath: /stone-exam-ui/src/views/BroswerCompatibility.vue
-->
<template>
  <div class="common-bg container">
    <simple-header :hasRight="false" />
    <div class="content">
      <div class="title">
        <span class="label">浏览器不兼容</span>
      </div>
      <div class="detail">
        尊敬的用户：<br />
        您好！<br />
        <br />
        为了您有更好的用户操作体验，请使用最新版谷歌浏览器（版本要求：80以上）<br />当前版本：<span
          class="version"
        >
          {{ browserInfo.browser }}：{{ browserInfo.ver }}
        </span>
        <br />
        <br />
        您可点击下方按钮下载并安装！给您带来的不便，在此表示歉意！
      </div>
      <CustomButton
        class="gap"
        :clickMethod="download"
        title="下载最新版谷歌浏览器"
        :isPlain="false"
      ></CustomButton>
      <div class="attention">
        注意：<br />不要把此页面路径复制到新的浏览器中打开，此路径不是登录路径，务必使用通知邮件或者短信中的路径，复制到新浏览器中打开！
      </div>
    </div>
  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import CustomButton from '@/components/CustomButton'
import getBrowserInfo from '@/utils/browserInfo'

export default {
  components: {
    SimpleHeader,
    CustomButton
  },
  data() {
    return {
      browserInfo: {
        browser: '',
        ver: ''
      }
    }
  },
  methods: {
    firstUpperCase(str) {
      return str.replace(/\b(\w)(\w*)/g, function($0, $1, $2) {
        return $1.toUpperCase() + $2.toLowerCase()
      })
    },
    // getBrowserInfo() {
    //   const Sys = {}
    //   const ua = navigator.userAgent.toLowerCase()
    //   const re = /(msie|firefox|chrome|opera|version).*?([\d.]+)/
    //   const m = ua.match(re)
    //   Sys.browser = this.firstUpperCase(m[1].replace(/version/, "'safari"))
    //   Sys.ver = m[2].split('.')[0]
    //   console.log(ua)
    //   return Sys
    // },
    download() {
      window.location.href = 'https://www.google.cn/chrome/'
    }
  },
  mounted() {
    this.browserInfo = getBrowserInfo()
    this.browserInfo.ver = this.browserInfo.shell
      ? this.browserInfo.shellVs
      : this.browserInfo.supporterVs
    this.browserInfo.browser = this.browserInfo.shell
      ? this.browserInfo.shell
      : this.browserInfo.supporter
    console.log(this.browserInfo)
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  .content {
    width: calc(100% - 664px);
    box-shadow: 0 0 18px #ddd;
    background-color: #fff;
    border-radius: 4px;
    padding: 40px 0 48px;
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;
    margin-top: 40px;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      .iconfont {
        margin-right: 10px;
        font-size: 40px;
      }
      font-size: 24px;
    }
    .detail {
      margin: 40px 0;
      font-size: 16px;
      line-height: 2;
      .version {
        font-size: 24px;
        color: #f35a5a;
      }
    }
    .attention {
      line-height: 2;
      // font-size: 12px;
      color: #f35a5a;
    }
    .gap {
      text-align: center;
    }
  }
}
</style>
