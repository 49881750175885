var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "common-bg container" },
    [
      _c("simple-header", { attrs: { hasRight: false } }),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm._m(0),
          _c("div", { staticClass: "detail" }, [
            _vm._v(" 尊敬的用户："),
            _c("br"),
            _vm._v(" 您好！"),
            _c("br"),
            _c("br"),
            _vm._v(
              " 为了您有更好的用户操作体验，请使用最新版谷歌浏览器（版本要求：80以上）"
            ),
            _c("br"),
            _vm._v("当前版本："),
            _c("span", { staticClass: "version" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.browserInfo.browser) +
                  "：" +
                  _vm._s(_vm.browserInfo.ver) +
                  " "
              ),
            ]),
            _c("br"),
            _c("br"),
            _vm._v(
              " 您可点击下方按钮下载并安装！给您带来的不便，在此表示歉意！ "
            ),
          ]),
          _c("CustomButton", {
            staticClass: "gap",
            attrs: {
              clickMethod: _vm.download,
              title: "下载最新版谷歌浏览器",
              isPlain: false,
            },
          }),
          _vm._m(1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "label" }, [_vm._v("浏览器不兼容")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "attention" }, [
      _vm._v(" 注意："),
      _c("br"),
      _vm._v(
        "不要把此页面路径复制到新的浏览器中打开，此路径不是登录路径，务必使用通知邮件或者短信中的路径，复制到新浏览器中打开！ "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }